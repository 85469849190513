export default {
  'app-importadmin': 'Importadmin',
  'app-importadmin-example-key': 'app-importadmin-example-key',
  'app-importadmin-add-title': 'Export erstellen',
  'app-importadmin-add-description': 'Wählen Sie die Daten aus, die Sie exportieren möchten. Bestimmte Services haben Abhängigkeiten, die ebenfalls exportiert werden müssen. Der Export wird via Azure Functions erstellt und als ZIP in einem sicheren CDN gespeichert. Die Bearbeitung kann ein paar Minuten in Anspruch nehmen.',
  'app-importadmin-title-placeholder': 'Platzhalter',
  'app-importadmin-required-service': 'Benötigte Services: %s',
  'app-importadmin-single-title': 'Import',
  'app-importadmin-created-at': 'Erstellt am',
  'app-importadmin-company': 'Firma',
  'app-importadmin-project': 'Projekt',
  'app-importadmin-filesize': 'Dateigröße',
  'app-importadmin-selected-services': 'Ausgewählte Services',
  'app-importadmin-statistics': 'Statistiken',
  'app-importadmin-target-project': 'Zielprojekt',
  'app-importadmin-import-title': 'Daten importieren',
  'app-importadmin-cdn': 'CDN URL',
  'app-importadmin-cdn-description': 'Url ohne https und slashes z.B. "cdn2.lilaquadrat.de" . Mediendaten in Editor Datensätzen werden mit dieser CDN URL aktualisiert, falls CDN ausgefüllt wurde.',
  'app-importadmin-updated-at': 'Aktualisiert am',
  'app-importadmin-state': 'Status',
  'app-importadmin-single-job-title': 'Import Job',
  'app-importadmin-created': 'Erstellt',
  'app-importadmin-exports-title': 'Exporte',
};