import MainStore, { state } from '@store/main.store';
import GenericStore from '@interfaces/GenericStore.interface';
import TextEditorStoreState from './TextEditorStoreState.interface';

const TextEditorStore: GenericStore<TextEditorStoreState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    active: false,
    content: {},
  },

  mutations: {

    active(localState, active) {

      localState.active = active;

    },

    content(localState, content) {

      localState.content = content;

    }

  },

  actions: {

  },

};

export default TextEditorStore;
