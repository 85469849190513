import { HandleLock } from '@libs/lila-auth';
import { state } from '@store/main.store';
import log from 'loglevel';
import Vue from 'vue';
import insightsPlugin from '@plugins/insights';
import authPlugin from '@plugins/auth';
import translatePlugin from '@plugins/translations';
import Router from 'vue-router';
import { Store } from 'vuex';
import appsPlugin, { AppsPlugin } from '@plugins/apps';
import helpersPlugin from '@plugins/helpers';
import { ExtComponent, vue } from '@libs/lila-component';
import Settings from '@libs/lila-settings';
import MainStoreState from '@store/mainStoreState.interface';
import de from 'translations/de';
import handlFullscreen from './handleFullscreens';
import Keyboard from './keyboard';
import initCallbackHook from './initCallbackHook';

export default async (settings: { router: Router, store: Store<MainStoreState> }) => {

  log.info('%c[STUDIO-APP]%c%c[MOUNT]%c start', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');

  if (state.settings.insights) {

    log.info('%c[STUDIO-APP]%c%c[PLUGINS]%c install insights', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');
    Vue.use(insightsPlugin, state.settings.insights);

  }
  
  log.info('%c[STUDIO-APP]%c%c[PLUGINS]%c install translate', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');
  Vue.use(translatePlugin, { store: settings.store });
  vue.prototype.$translations.add(de, 'de');
  vue.prototype.$translations.select('de');

  log.info('%c[STUDIO-APP]%c%c[PLUGINS]%c install auth', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');
  Vue.use(authPlugin, { store: settings.store });


  log.info('%c[STUDIO-APP]%c%c[PLUGINS]%c install apps', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');
  Vue.use(appsPlugin, { store: settings.store, router: settings.router });
 
  log.info('%c[STUDIO-APP]%c%c[PLUGINS]%c install helpers', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');
  Vue.use(helpersPlugin, { store: settings.store, router: settings.router });


  AppsPlugin.registerInternal('appstore', settings.store, settings.router);
  AppsPlugin.registerInternal('team', settings.store, settings.router);
  AppsPlugin.registerInternal('history', settings.store, settings.router);
  AppsPlugin.registerInternal('learn', settings.store, settings.router);

  settings.router.beforeEach((to, from, next) => {

    const storeBase = to.params.company && to.params.project 
      ? settings.store.state.Appstore.company.installed 
      : settings.store.state.Appstore.project.installed;

      
      if (state.Navigation.status !== 'done') return next();
      
      if (!state.Navigation.init && state.Navigation.appEntry && storeBase && state.Navigation.status === 'done') {
        
        settings.store.commit('Navigation/init');
        
      }
      
      settings.store.commit('Navigation/targetComponent', to.name);
      settings.store.commit('Navigation/contentUpdate', !!(to.name === from.name));
      
    return Settings.routeGuard(to, next);

  });

  settings.router.beforeResolve(async (to, from, next) => {

    let preloadedData: unknown;
    
    if (!state.Navigation.init && !state.Navigation.appEntry) {

      settings.store.commit('Navigation/init');

      if (state.Settings.settings.home && to.name === 'me-home') {

        return next(state.Settings.settings.home);

      }

    }

    const matched: any = [...settings.router.getMatchedComponents(to)].pop();
    const methods = matched?.options?.methods as ExtComponent;

    console.log(matched);

    return settings.store.dispatch('Navigation/start')
    .then(() => {
      
        if (to.name !== from.name) settings.store.dispatch('Navigation/loading');

        handlFullscreen(to, settings.store);
        if (!HandleLock(to, from, next)) return false;

        if (typeof methods?.preloadDataPre === 'function') {

          return methods.preloadDataPre(to.params, to.query, settings.store, to);

        }

        if (typeof methods?.asyncData === 'function') {

          return methods.asyncData(to.params, to.query, settings.store, to);

        }


        return Promise.resolve();

      })
      .then((data) => {

        preloadedData = data;
        return settings.store.dispatch('Navigation/resolving');

      })
      .then(() => {

        if (typeof methods?.preloadDataPost === 'function') {

          return methods.preloadDataPost(preloadedData, to.params, to.query, settings.store, to);

        }

        return Promise.resolve();

      })
      .then(() => {

        settings.store.dispatch('Navigation/done');

        next();
        return true;

      })
      .catch((error) => {

        log.error(error);

        if (typeof methods?.preloadError === 'function') {

          methods.preloadError(error, settings.store);

        }

        return settings.store.dispatch('Navigation/resolving');

      })
      .then(() => {

        settings.store.dispatch('Navigation/done');
        next();

        return true;

      })
      .catch((e) => {

        log.error(e);

      });

  });

  settings.router.afterEach(() => {

    if (state.Navigation.animationTime === 0 && state.Navigation.init) {

      settings.store.commit('Navigation/enableAnimation');

    }

  });

  settings.router.onReady(() => {

    log.info('%c[STUDIO-APP]%c%c[ROUTER]%c ready', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #FFF; color: #3f2d56;');

    window.dispatchEvent(new Event('hydrated'));

  });

  const APP = new Vue(settings);

  APP.$mount(state.settings.mount);

  if(state.user?.id) await initCallbackHook(settings.store, settings.router);

  log.info('%c[STUDIO-APP]%c%c[MOUNT]%c ready', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #FFF; color: #3f2d56;');

  Keyboard(APP);

};
