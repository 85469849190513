/* eslint-disable @typescript-eslint/no-var-requires */
import vue from 'vue';
import Vuex, { Store } from 'vuex';
import { Call } from '@libs/lila-call';
import { Auth } from '@libs/lila-auth';
import AppMedia from '@apps/media/store/app.store';
import Company from '@store/company.store';
import Project from '@store/project.store';
import Translations from '@store/translations.store';
import Cache from '@store/cache.store';
import AppEditorMainStore from '@apps/editor/store/main.store';
import AppEditorDataStore from '@apps/editor/store/data.store';
import AppEditorExtensionStore from '@apps/editor/store/extension.store';
import Navigation from '@store/navigation.store';
import Settings from '@store/settings.store';
import Content from '@store/content.store';
import Appstore from '@apps/appstore/store/app.store';
import AppstoreOverlay from '@apps/appstore/store/app-overlay.store';
import AppPublish from '@apps/publish/store/main.store';
import AppHistory from '@apps/history/store/main.store';
import AppPartner from '@apps/partner/store/app.store';
import AppGfm from '@apps/gfm/store/app.store';
import AppGfmMain from '@apps/gfm/store/main.store';
import AppLearn from '@apps/learn/store/main.store';
import AppHosting from '@apps/hosting/store/main.store';
import AppLearnIntegrated from '@apps/learn/store/integrated.store';
import AppTracker from '@apps/tracker/store/main.store';
import AppCustomers from '@apps/customers/store/main.store';
import AppLists from '@apps/lists/store/main.store';
import AppListParticipants from '@apps/lists/store/participants.store';
import log from 'loglevel';
import { Me, Permissions } from '@lilaquadrat/studio/lib/interfaces';
import TypePermissions from '@interfaces/TypePermissions.interface';
import StudioSDK from '@libs/StudioSDK';
import IndexedDb from '@libs/indexedDb';
import AppListsExtensionStore from '@apps/lists/store/extension.store';
import availableApps from '@apps/apps';
import { MePermissions } from '@lilaquadrat/interfaces';
import AppCustomersExtensionStore from '@apps/customers/store/extension.store';
import MainStoreState from './mainStoreState.interface';
import AppTimeObjectStore from '../apps/tracker/database/tracker.objectstore';
import AppHostingAdmin from '../apps/hostingadmin/store/main.store';
import AppPublishadmin from '../apps/publishadmin/store/main.store';
import AppImportadmin from '../apps/importadmin/store/main.store';
import AppPublishadminExtensionStore from '../apps/publishadmin/store/extension.store';
import AppHostingAdminCertificatesActions from '../apps/hostingadmin/store/certificatesActions.store';
import AppHostingAdminDomains from '../apps/hostingadmin/store/domains.store';
import AppHostingAdminCommands from '../apps/hostingadmin/store/commands.store';
import AppPayment from '../apps/payment/store/main.store';
import TextEditor from './textEditor.store';


vue.use(Vuex);

const state: MainStoreState = {
  title: '',
  media: '',
  width: 0,
  height: 0,
  settings: {},
  translation: {},
  overlay: {
    content: {},
    active: false,
  },
  user: {
    termsAccepted: false,
  },
  permissions: [] as TypePermissions[],
  permissionsAll: [] as MePermissions[],
  authentication: 'check',
  apps: [],
  quickmenu: false,
  lock: null,
  authToken: null,
  activeApp: null,
  fullscreen: false,
  inited: false,
  clipboard: null,
  names: {},
  actions: [],
  actionsComponents: []
};
const mainStore = new Vuex.Store({
  state,
  strict: true,
  devtools: true,
  modules: {
    AppMedia,
    Company,
    Project,
    AppEditorData: AppEditorDataStore,
    AppEditorMain: AppEditorMainStore,
    AppEditorExtension: AppEditorExtensionStore,
    AppListsExtension: AppListsExtensionStore,
    AppPublish,
    AppHistory,
    AppPartner,
    AppGfm,
    AppGfmMain,
    AppLearn,
    TextEditor,
    AppLearnIntegrated,
    AppTracker,
    AppHosting,
    Content,
    Cache,
    Navigation,
    Settings,
    Appstore,
    AppstoreOverlay,
    AppCustomers,
    AppCustomersExtension: AppCustomersExtensionStore,
    AppLists,
    AppListParticipants,
    AppPayment,
    AppPublishadmin,
    AppPublishadminExtension: AppPublishadminExtensionStore,
    AppHostingAdmin,
    AppHostingAdminCertificatesActions,
    AppHostingAdminDomains,
    AppHostingAdminCommands,
    AppImportadmin,
    Translations
  },
  plugins: [
    async () => {

      IndexedDb.register([AppTimeObjectStore]);
      await IndexedDb.connect(4);

    },
  ],
  // plugins: [
  //   createLogger({
  //     collapsed: false,
  //     filter(mutation, stateBefore, stateAfter) {

  //       // returns `true` if a mutation should be logged
  //       // `mutation` is a `{ type, payload }`
  //       return !!mutation.type.match(/^AppLearn/);

  //     },
  //     actionFilter(action, state) {

  //       // same as `filter` but for actions
  //       // `action` is a `{ type, payload }`
  //       return !!action.type.match(/^AppLearn/);

  //     },
  //   }),
  // ],
  getters: {
    endpoints(mainState) {

      return mainState.settings.endpoints || [];

    },
    endpointsSDK(mainState) {

      return {
        api: mainState.settings.endpoints[0].url,
        media: mainState.settings.endpoints[1].url,
      };

    },
    companyProject(mainState) {

      return {
        company: mainState.Company.company,
        project: mainState.Project.project,
      };

    },
    cdn(mainState) {

      return mainState.settings.cdn || '';

    },
  },
  mutations: {

    init(mainState) {

      mainState.inited = true;

    },


    setMedia(mainState, data) {

      mainState.media = data;

    },

    setHeight(mainState, data) {

      mainState.height = data;

    },

    setWidth(mainState, data) {

      mainState.height = data;

    },


    setSettings(mainState, data) {

      mainState.settings = data;

    },

    setTranslation(mainState, data) {

      mainState.translation = data;

    },

    setOverlay(mainState, data) {

      mainState.overlay.content = data;
      mainState.overlay.active = true;

    },

    clearOverlay(mainState) {

      mainState.overlay.content = {};

    },

    setFullscreen(mainState, active: boolean) {

      mainState.fullscreen = active;

    },

    // setEditorAvailable(mainState, data: { available: boolean, type: string, value: string }) {

    //   mainState.editor.available[data.type] = data;

    // },

    // setEditorModules(mainState, data: string[]) {

    //   mainState.editor.modules = data;

    // },

    setUserSettings(mainState, settings) {

      mainState.user.settings = settings;

    },

    setUser(mainState, user) {

      mainState.user.prename = user.prename;
      mainState.user.lastname = user.lastname;
      mainState.user.id = user.id;
      mainState.user.email = user.email;
      mainState.user.state = user.state;
      mainState.user.settings = user.settings;
      mainState.user.termsAccepted = user.termsAccepted || false;

    },

    setPermissions(mainState, data: TypePermissions) {

      const index = mainState.permissions.findIndex((single) => single.type === data.type);
      const { permissions } = mainState;

      permissions[index] = data;

      if (index >= 0) {

        mainState.permissions = permissions.slice();

      } else {

        mainState.permissions.push(data);

      }

    },

    setPermissionsAll(mainState, data: Permissions) {

      mainState.permissionsAll = data;

    },

    setQuickmenu(mainState, type: string) {

      mainState.quickmenu = type;

    },

    clearQuickmenu(mainState) {

      mainState.quickmenu = false;

    },

    lock(mainState, routeName) {

      mainState.lock = routeName;

    },

    unlock(mainState) {

      mainState.lock = null;

    },

    setAuthToken(mainState, token: string) {

      mainState.authToken = token;

    },

    apps(mainState, app: string) {

      mainState.apps.push(app);

    },

    activeApp(mainState, app: string) {

      mainState.activeApp = app;

    },

    copy(mainState, content: unknown) {

      mainState.clipboard = content;

    },

    names(mainState, names: Record<string, string>) {

      mainState.names = names;

    },

    actions(mainState, actions: unknown[]) {

      mainState.actions.push(...actions);

    },

    actionsComponents(mainState, componentName: string) {

      mainState.actionsComponents.push(componentName);

    },

  },

  actions: {
    hideOverlay() {

      this.state.overlay.active = false;

    },

    fullscreen(store, mainState: boolean) {

      if (!document) return;

      const { body } = document;

      if (mainState) {

        body.classList.add('fullscreen');

      } else {

        body.classList.remove('fullscreen');

      }

      store.commit('setFullscreen', mainState);

    },

    getPermissions(store, data?: { company: string, project: string }) {

      const sdk = new StudioSDK('publish-app', AppEditorDataStore.sdkOptions());
      let type = 'user';

      if (data) {

        if (data.company) {

          type = 'company';

        }

        if (data.project) {

          type = 'project';

        }

      }

      return sdk.me.single(data)
        .then((response) => {

          this.commit('setPermissions', { type, permissions: response.data });

        })
        .catch((e) => {

          log.error(e);
          throw new Error(e);

        });

    },

    getPermissionsAll() {

      const sdk = new StudioSDK('publish-app', AppEditorDataStore.sdkOptions());

      return sdk.me.all()
        .then(async (response) => {

          const apps = await this.dispatch('getAppsAndOverlay', response.data);

          this.commit('Appstore/setAppsForOverlay', apps.overlay);
          this.commit('Appstore/setAppsToInstall', apps.appsToInstall);
          this.commit('Translations/extend', {key: 'de', translations: apps.names});

          this.commit('setPermissionsAll', response.data);

        });

    },

    getAppsAndOverlay(store, permissionsAll: Permissions) {

      const appsWithOverlay = Object.values(availableApps).filter((single) => single.overlay);
      const compatibleAppsNames = appsWithOverlay.map((single) => single.name);
      const renderApps = [];
      const names: Record<string, string> = {};
      const appsToInstall = new Set();

      permissionsAll.forEach((single) => {

        names[`company:${single._id}`] = single.name;
   
         single.projects.forEach((project) => {

          names[`project:${single._id}:${[project.id]}`] = project.name;
   
            compatibleAppsNames.forEach((app) => {
   
               if(project.scope.includes(`${app}:create`)) {
   
                  availableApps[app].overlay.forEach((overlay) => {
   
                     appsToInstall.add(app);
   
                     renderApps.push({app, company: single._id, project: project.id, element: overlay});
                  
                  });
   
   
               }
   
            });
   
         });
   
      });
   
      return {overlay: renderApps, appsToInstall, names};

    },

    getMe(store) {

      const call = new Call<Me>('system', store.getters.endpoints);

      return call.get('api', '/me', undefined, Auth.getAuthHeader())
        .then((data) => {

          this.commit('setUser', data.r);

          if (!data.r.termsAccepted) {

            return this.commit('lock', 'me-settings-personal');

          }


          return data.r;

        })
        .catch((e) => {

          log.error(e);
          throw new Error(e);

        });

    },

    health() {

      const sdk = new StudioSDK('system', AppEditorDataStore.sdkOptions());

      return sdk.health.health();

    },

    initError() {

      let seconds = 60;

      document.querySelector('#notice h1').textContent = 'something is wrong';
      document.querySelector('#notice h3').textContent = 'This Site will reload itself in, to check if the problem is fixed.';

      setInterval(() => {

        seconds -= 1;

        document.querySelector('#notice h4').textContent = `${seconds} seconds until reload`;

      }, 1000);

      setTimeout(() => {

        window.location.reload();

      }, 60000);

    },

    saveMe(store, data: Me) {

      const call = new Call<Me>('system', store.getters.endpoints);

      return call.put('api', '/me', data, Auth.getAuthHeader())
        .then((response) => response.r);

    },

    extendActions(store, data: {source: string, actions: any[]}) {

      store.commit('actionsComponents', data.source);
      store.commit('actions', data.actions);

    },

    setMockSettings(store) {

      document.querySelector('body').classList.add('ismock');

      store.commit('setSettings', {
        endpoints: [{
          name: 'api',
          url: 'http://localhost:9090',
          context: false,
        },
        {
          name: 'media',
          url: 'http://localhost:8080',
          context: false,
        }],
        cookie: {
          name: 'lilaquadrat-studio',
        },
        mount: '#app',
        title: {
          short: '- STUDIO',
          full: 'lilaquadrat STUDIO',
        },
        cdn: 'https://cdn2.lilaquadrat.de',
        description: 'STUDIO',
        editor: {},
        dev: true,
        noAnimation: true,
        mock: true,
        devUser: {
          prename: 'Mock User',
        },
      });

    },

  },
});
const useStore = () => mainStore as Store<MainStoreState>;

export {
  state,
  useStore
};
export default mainStore;