export default {
  'app-team': 'Team',
  'app-team-permissions-create': 'Elemente erstellen',
  'app-team-permissions-edit': 'Elemente bearbeiten',
  'app-team-permissions-remove': 'Elemente entfernen',
  'app-team-permissions-history': 'Historie einsehen',
  'app-team-permissions-read': 'Elemente einsehen',
  'app-team-permissions-publish': 'Datensaetze veroffentlichen',
  'app-team-permissions-settings': 'Einstellungen bearbeiten',
  'app-team-permissions-toggle': 'Apps aktivieren/deaktivieren',
  'app-team-permissions-app': 'Design aktualisieren',
  'app-team-permissions-secret': 'Secret anzeigen',
  'app-team-permissions-refreshSecret': 'Secret aktualisieren',
  'app-permissions-permissions-read': 'Teammitglieder anzeigen',
  'app-permissions-permissions-create': 'Teammitglieder erstellen',
  'app-permissions-permissions-edit': 'Teammitglieder bearbeiten',
  'app-permissions-permissions-remove': 'Teammitglieder entfernen',
  'app-permissions-permissions-history': 'Historie anzeigen',
  'app-settings-permissions-read': 'Firmen - oder Projekteinstellungen anzeigen',
  'app-settings-permissions-edit': 'Firmen - oder Projekteinstellungen bearbeiten',
  'app-settings-permissions-history': 'Historie anzeigen',
  'app-permissions-project': 'Projekte',
  'app-permissions-company': 'Firma',
  'app-permissions-partner': 'Partner',
  'app-permissions-history': 'Historie',
  'app-permissions-hosting': 'Hosting',
  'app-permissions-publish': 'Publish',
  'app-permissions-import': 'Import',
  'app-permissions-app': 'Appstore',
  'app-permissions-customers': 'Kunden',
  'app-permissions-settings': 'Einstellungen',
  'app-permissions-permissions': 'Teams',
  'app-project-permissions-read': 'Projekte anzeigen',
  'app-project-permissions-create': 'Projekt erstellen',
  'app-project-permissions-edit': 'Projekt bearbeiten',
  'app-project-permissions-remove': 'Projekt entfernen',
  'app-project-permissions-history': 'Historie anzeigen',
  'app-project-permissions-copy': 'Projekt kopieren',
  'app-company-permissions-list': 'Firmen anzeigen',
  'app-company-permissions-create': 'Firma erstellen',
  'app-company-permissions-edit': 'Firma bearbeiten',
  'app-company-permissions-remove': 'Firma entfernen',
  'app-company-permissions-admin': 'Firmen Admin',
  'app-company-permissions-history': 'Historie anzeigen',
};
