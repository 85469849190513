import LoadElements from '@mixins/loadElements';
import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

LoadElements.appComponents(require.context('./partials', true, /[a-z]\w+\.partial\.(ts|vue)$/, 'lazy'), 'partials', 'importadmin');

const app: InstallableApp = {
  name: 'importadmin',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppImportadmin',
};

export default app;
