import vue from 'vue';

// vue.filter('formatBytes', (bytes: number, decimals: number) => {

//   if (!bytes) return '0 Bytes';

//   const k = 1000;
//   const dm = decimals < 0 ? 0 : decimals;
//   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//   const i = Math.floor(Math.log(bytes) / Math.log(k));

//   return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;

// });
export default function formatBytes(bytes: number, decimals: number) {

  if (!bytes) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;

}