import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-customers" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/a/customers/import/:site?',
    name: 'app-customers-company-import',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('import.screen.vue'),
    meta: {
      animationGroup: 'customers',
      app: 'customers',
      parent: true,
      rememberSettings: true,
    }
  },
  {
    path: '/:company/a/customers/:site?',
    name: 'app-customers-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('home.screen.vue'),
    meta: {
      animationGroup: 'customers',
      app: 'customers',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'single/:id',
        component: loadScreen('overview.screen.vue'),
        meta: {
          sidescreen: true,
          defaultRoute: 'app-customers-company-single',
          app: 'customers',
        },
        children: [
          {
            path: 'edit',
            component: loadScreen('edit.screen.vue'),
            name: 'app-customers-company-edit',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-customers-company-edit',
              app: 'customers',
            },
          },
          {
            path: '',
            component: loadScreen('single.screen.vue'),
            name: 'app-customers-company-single',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-customers-company-single',
              app: 'customers',
            },
          },
        ],
      },
      {
        path: 'add',
        component: loadScreen('overview.screen.vue'),
        meta: {
          sidescreen: true,
          defaultRoute: 'app-customers-company-add',
          app: 'customers',
        },
        children: [
          {
            path: '',
            component: loadScreen('edit.screen.vue'),
            name: 'app-customers-company-add',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-customers-company-add',
              app: 'customers',
            },
          },
        ],
      },
    ],
  }
];
