import { UnifiedRouteGuard } from '@libs/lila-auth';

const loadScreen = (screen: string) => () => import(
  /* webpackChunkName: "app-importadmin" */
  `./screens/${screen}`
).then((m) => m.default);

export default [
  {
    path: '/:company/a/importadmin/exports',
    name: 'app-importadmin-company-exports',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('exports-home.screen.vue'),
    meta: {
      animationGroup: 'importadmin',
      app: 'importadmin',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'add',
        component: loadScreen('add.screen.vue'),
        name: 'app-importadmin-company-add',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-importadmin-company-add',
          app: 'importadmin',
        },
      },
      {
        path: 'single/:exportCompany/:exportProject/:timestamp',
        component: loadScreen('single.screen.vue'),
        name: 'app-importadmin-company-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-importadmin-company-single',
          app: 'importadmin',
        },
      },
    ],
  },
  {
    path: '/:company/a/importadmin/:site?',
    name: 'app-importadmin-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: loadScreen('jobs-home.screen.vue'),
    meta: {
      animationGroup: 'importadmin',
      app: 'importadmin',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'single/:id',
        component: loadScreen('single-job.screen.vue'),
        name: 'app-importadmin-company-single-job',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-importadmin-company-single-job',
          app: 'importadmin',
        },
      },
    ],
  },
];
