import InstallableApp from '../interfaces/InstallableApp.interface';
import Editor from './editor/app';
import Team from './team/app';
import Publish from './publish/app';
import Appstore from './appstore/app';
import Media from './media/app';
import History from './history/app';
import Partner from './partner/app';
import Gfm from './gfm/app';
import Learn from './learn/app';
import Hosting from './hosting/app';
import Tracker from './tracker/app';
import Customers from './customers/app';
import Lists from './lists/app';
import Payment from './payment/app';
import HostingAdmin from './hostingadmin/app';
import PublishAdmin from './publishadmin/app';
import ImportAdmin from './importadmin/app';

const availableApps: Record<string, InstallableApp> = {
  editor: Editor,
  team: Team,
  publish: Publish,
  appstore: Appstore,
  media: Media,
  history: History,
  partner: Partner,
  learn: Learn,
  hosting: Hosting,
  tracker: Tracker,
  gfm: Gfm,
  customers: Customers,
  lists: Lists,
  payment: Payment,
  hostingadmin: HostingAdmin,
  publishadmin: PublishAdmin,
  importadmin: ImportAdmin
};

export default availableApps;
