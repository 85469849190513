import GenericStore from '@interfaces/GenericStore.interface';
import MainStore, { state } from '@store/main.store';
import { DataObject, Importadmin } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@libs/StudioSDK';
import { ImportJob, ObjectIdString } from '@lilaquadrat/interfaces';
import AppImportadminMainState from '../interfaces/main-state.interface';
// import StudioSDK from '@libs/StudioSDK';

const AppImportadminStore: GenericStore<AppImportadminMainState> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    exports: {},

    search: {
      tags: [],
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    single: null,

    job: null,

  },

  mutations: {

    data(store, data: DataObject<ImportJob[]>) {

      store.data = data;

    },

    export(store, data: DataObject<ExportData>) {

      store.exports = data;

    },

    single(store, data: Importadmin) {

      store.single = data;

    },

    job(store, data: ImportJob) {

      store.job = data;

    },


    updateSearch(appImportadminState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appImportadminState.search);

      if (!update.data.length) {

        appImportadminState.search[update.type] = null;
        return;

      }

      console.log(update, appImportadminState.search);

      appImportadminState.search[update.type] = update.data;

    },

    setSearch(appImportadminState, search: { tags?: string[], search?: string, type?: string }) {

      appImportadminState.search = search;

    },

  },

  actions: {

    get(store) {

      const sdk = new StudioSDK('importadmin-app', AppImportadminStore.sdkOptions());

      return sdk.import.admin.list();

    },

    getJobs(store, data: {query: {sort: string, order: string}, settings: {site?: string}}) {

      const sdk = new StudioSDK('importadmin-app', AppImportadminStore.sdkOptions());

      return sdk.import.admin.jobs.list(data.settings.site, data.query);

    },

    createExport(store, data: {services: string[], projectId: string}) {

      const sdk = new StudioSDK('importadmin-app', AppImportadminStore.sdkOptions());

      return sdk.import.admin.createExport(data.services, data.projectId);

    },

    createImport(store, data: {services: string[], projectId: string, path: string, cdn?: string}) {

      const sdk = new StudioSDK('importadmin-app', AppImportadminStore.sdkOptions());

      return sdk.import.admin.createImport(data.services, data.path, data.projectId, data.cdn);

    },


    single(store, data: {exportCompany: string, exportProject: string, timestamp: string}) {

      const sdk = new StudioSDK('importadmin-app', AppImportadminStore.sdkOptions());

      return sdk.import.admin.single(data.exportCompany, data.exportProject, data.timestamp);

    },

    singleJob(store, internalId: ObjectIdString) {

      const sdk = new StudioSDK('importadmin-app', AppImportadminStore.sdkOptions());

      return sdk.import.admin.jobs.single(internalId);

    },

    /**
     * This action will be called, when the app will be activated
     */
    activate(store, params) {

      return Promise.resolve(true);

    },
  },

};

export default AppImportadminStore;
